<template>
    <div style="background-color: #EEECEC;" data-app>
        
            <h1 class=" grg_font_outline grg_font_family_reb grg_title_medium text-uppercase position-relative "
            style=" --color_solid: black; --color_outline: black; --pos_x: 0.05em; --pos_y: -0.05em; line-height: 1; padding-top:3% "
            data-utilerias="flechas_blancas"
            ><center> LÍNEA <span class=" grg_font_outline grg_font_family_reb grg_title_medium text-uppercase position-relative "
                style=" --color_solid: #bd203b;  --color_outline: #bd203b; --pos_x: 0.05em; --pos_y: -0.05em; line-height: 1; "
                data-utilerias="flechas_blancas">ÉTICA</span></center>
        </h1>
        
        <h1 class="grg_font_family_reb grg_text_extra_big text-uppercase"
            style="color: #626462; padding-bottom:4%;padding-top:1%">
            <center>Hablemos de una forma confidencial <br>y libre de represalias</center>
        </h1>
        <p class="txtGRG" style="">En <strong>Grupo Restaurantero Gigante </strong>tenemos el compromiso actual con los más altos estándares éticos e integridad, apegándonos a los lineamientos que hemos establecido en nuestro Código de Ética y Conducta, así mismo, estamos interesados en las relaciones a largo plazo con todos nuestros socios comerciales.
            <br><br>Por tal motivo <strong> fomentar los valores, la legalidad, honestidad, equidad, respeto y transparencia.</strong>
            <br><br>Ponemos a su disposición un canal de comunicación que facilite el reporte de fraudes, irregularidades, conductas inapropiadas y cualquier otra situación que quebrante el Código de Ética y Conducta, así como las políticas del Grupo. 
        </p>
        <div style="padding-bottom:1% ;">
            <v-container>
                <div >
                    <v-row style="justify-content: center; ">
                        <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                            <div>
                                <v-card class="mx-auto"  style="border-radius:20px;">
                                    <a href="https://grg.lineaetica.live/">
                                    <v-card-title style="color:black"> 
                                        <span class="text-h6 font-weight titulo-denuncias2 " style=""><strong>Página Web</strong></span> 
                                        <img src="https://img.icons8.com/wired/64/bd203b/domain.png" class="iconos" style=""/>
                                    </v-card-title>
                                        
                                    <v-card-text class="text-h5  font-denuncias " style="">
                                        <a href="https://grg.lineaetica.live/" target="_blank" style="color: black;">
                                        https://grg.lineaetica.live/
                                        </a>
                                    </v-card-text></a>
                            </v-card>
                            </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="3" lg="3" xl="3" >
                            <div>
                                <v-card class="mx-auto"  style="border-radius:20px;">
                                    <a href="tel:+8000052520">
                                    <v-card-title style="color:black"> 
                                        <span class="text-h6 font-weight titulo-denuncias" style=""><strong>Teléfono</strong>
                                            <img src="https://img.icons8.com/wired/64/bd203b/phone.png"  class="iconos"/>
                                        </span> 
                                    </v-card-title>

                                        <v-card-text class="text-h5 font-weight-bold;" style="padding-top:0px; font-size: 18px;">
                                            <a href="tel:+5521225727" target="_blank" style="color: black;">
                                                +800 005 2520
                                            </a>
                                    </v-card-text></a>
                                </v-card>
                            </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                            <div>
                                <v-card class="mx-auto"   style="border-radius:20px; " >
                                <a href="mailto:grg@lineaetica.live" target="_blank">
                                    <v-card-title style="color:black"> <span class="text-h6 font-weight titulo-denuncias" style="">
                                        <strong>Correo</strong>
                                        <img src="https://img.icons8.com/ios/64/bd203b/email-open.png" class="iconos" /> 
                                    </span> </v-card-title>
                                        <v-card-text class="text-h5 " style="">
                                            <a href="mailto:grg@lineaetica.live" target="_blank" style="color: black;">
                                                grg@lineaetica.live
                                            </a>
                                    </v-card-text></a>
                                </v-card>
                            </div>
                        </v-col>

                        <div>
                            <a href="https://api.whatsapp.com/send?phone=5215565385504&text=%C2%A1Hola!%20Me%20comunico%20de%20GRG,%20quiero%20realizar%20una%20denuncia,%20felicitaci%C3%B3n,%20queja%20o%20recomendaci%C3%B3n" target="_blank" >
                                <img width="80" height="80" class="imgWhatsapp" src="https://img.icons8.com/color/96/whatsapp--v1.png" alt="whatsapp--v1"/>
                            </a>
                        </div>
                    </v-row>
                </div>
            </v-container> 
        </div>

        <div>
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span>
                            <img src="https://img.icons8.com/ios-filled/50/457ABF/1-circle.png" class="circle"/>
                        <p class="titApps" >DESCARGA NUESTRA APP <v-btn @click="dialog=true" color="#039df7" height="25" style="color: #EEECEC;">VER VIDEO</v-btn>
                        </p>
                    <!-- <v-btn href="https://www.youtube.com/watch?v=G29SCxMILdo&t=43s" target="_blank" style="background-color:#039df7;color:#EEECEC;height: 25px;" >VER VIDEO</v-btn>  -->
                </span>
                        
                        <p class="txtApps">Nuestra App EthicsGlobal es única para que la puedas utilizar las 24 horas, los 365 días del año. Aquí podrás realizar llamadas o conversar en un chat, 
                            pero si lo deseas también puedes llenar el cuestionario que te llevará de la mano. Úsala, es el momento de escucharte.</p>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                    <div class="botones">
                                        <a href="https://apps.apple.com/mx/app/ethicsglobal/id1183586213" target="_blank">
                                            <img src="@/assets/img/denuncias/APPSTORE.png"  style="" class="apps"/>
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.ethicsglobal.app&pli=1" target="_blank">
                                            <img src="@/assets/img/denuncias/GooglePlay.png"  style="" class="apps"/>
                                        </a>
                                    </div><p class="txtApps2">*App operada por EthicsGlobal.</p>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                    <img src="@/assets/img/denuncias/app.png"  style="" class="appsimg"/>
                                </v-col>
                            </v-row> 
                        </v-container>
                        
                        
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <img src="https://img.icons8.com/ios-filled/50/457ABF/2-circle.png" class="circle"/>
                        <p class="txtQR">Entra a la App, escanea el QR o escribe el Código para utilizar la línea</p>
                        <v-container>
                            <v-row>
                                <v-col cols="6" sm="6" md="6" lg="6" xl="6" >
                                    <p class="txtQR-2" >GCG-5394-GRG</p>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" lg="6" xl="6" >
                                    <img src="@/assets/img/GRG.jpg"  style="" class="QR"/>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-dialog v-model="dialog" class="" width="725" >
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    <v-btn color="primary" text @click="dialog = false" style="text-align:end;" >
                        cerrar
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <iframe width="674" height="379" src="https://www.youtube.com/embed/G29SCxMILdo" title="EthicsGlobal App" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        data () {
            return {
            dialog: false,
            }
        },
    }
</script>




    <style>
    @media screen and (min-width: 320px) and (max-width: 600px){
        
        .container {
            max-width: 90vw;
            text-align-last: center;
        }
        .txtGRG{
            padding-left: 15%;
            padding-right: 15%;
            text-align: justify;
        }
        .iconos{
            height:40px; position:absolute; right:1rem; padding:0px; align-content: baseline;
        }
        .font-denuncias{
            font-size: 13px;line-height: 1;
        }
        .circle{
            width:20px; height:20px;display: inline-block;
        }
        .titApps{
            font-family:'Raleway_Bold';padding: 0;margin: 0;padding-bottom: 10px;
        }
        .txtApps{
            font-size: 13px;margin-bottom: 20px;text-align: center;
        }
        .apps{
            padding: 0;    margin: 0;    width: 30vw;            height: 35px;
        }
        .txtApps2{
            font-size: 10px;            margin-top: 0rem;
        }
        .appsimg{
            padding: 0;    margin: 0;    width: 50vw;    margin-top: -10px;
        }
        .txtQR{
            font-size: 15px;    font-family:'Raleway_Bold';
        }
        .txtQR-2{
            font-size: 17px; font-family:'Raleway_Bold';
            font-weight: 900;
            /*width: 15vw;*/
            height: 1.6rem;
            margin-top: 25px;
            margin-bottom: 25px;/*margin-left: 28px;*/
        }
        .QR{
            width: 100px;
            padding: 0;
            margin-top: -9px;
            margin-left: 1px;
            margin-bottom: 2rem; 
        }

        
    }

    @media screen and (min-width: 600px) and (max-width: 960px){
        .container {
            max-width: 90vw;
            text-align-last: center;
        }
        .txtGRG{
            padding-left: 15%;
            padding-right: 15%;
            text-align: justify;
        }
        .iconos{
            height:40px; position:absolute; right:1rem; padding:0px; align-content: baseline;
        }
        .font-denuncias{
            font-size: 13px;line-height: 1;
        }
        .circle{
            width:20px; height:20px;
        }
        .titApps{
            font-family:'Raleway_Bold';padding: 0;margin: 0; font-size: 16px;padding-bottom: 10px;
        }
        .txtApps{
            font-size: 14px;margin-bottom: 20px;text-align: center; padding-left: 10%;            padding-right: 10%;
        }
        .apps{
            padding: 0;    margin: 0;    width: 20vw;            height: 7vh;
        }
        .txtApps2{
            font-size: 11px;            margin-top: .5rem;padding-left: 10%;            padding-right: 10%;
        }
        .appsimg{
            padding: 0;    margin: 0;    width: 35vw;    margin-top: -10px;
        }
        .txtQR{
            font-size: 16px;    font-family:'Raleway_Bold';padding-left: 10%;            padding-right: 10%;
        }
        .txtQR-2{
            font-size: 17px; font-family:'Raleway_Bold';            font-weight: 900;
            height: 1.6rem;            margin-top: 25px;   margin-bottom: 25px;text-align: end;            margin-left: 180px;
        }
        .QR{
            width: 100px;            padding: 0;
            margin-top: -9px;            margin-left: -160px;            margin-bottom: 2rem; 
        }

    }

    @media screen and (min-width: 960px) and (max-width: 1280px){
        .container {
            max-width: 90vw;
            /*text-align-last: center;*/
        }
        .txtGRG{
            padding-left: 13%;
            padding-right: 10%;
            text-align: justify;
        }
        .iconos{
            height:40px; position:absolute; right:1rem; padding:0px; align-content: baseline;
        }
        .font-denuncias{
            font-size: 13px;line-height: 1;
        }
        .circle{
            width:20px; height:20px;margin-left: 18%;
        }
        .titApps{
            font-family:'Raleway_Bold';padding: 0;margin: 0;margin-left: 18%;padding-bottom: 10px;
        }
        .txtApps{
            font-size: 13px;margin-bottom: 20px;text-align: left;margin-left: 82px;
        }
        .botones{
            width: 18vw;    margin-left: 70px;    z-index: 10;    position: relative;
        }
        .apps{
            padding: 0;    margin: 0;    width: 9vw;            height: 27px;    /*margin-left: 65px;*/
        }
        .txtApps2{
            font-size: 10px;    margin-top: 0.5rem;    margin-left: 72px;    width: 160px;
        }
        .appsimg{
            padding: 0;    margin: 0;    width: 13vw;            margin-top: -30px;margin-left: 55px;
        }
        .txtQR{
            font-size: 15px;    font-family:'Raleway_Bold';    margin-left: 75px;    margin-right: 45px;
        }
        .txtQR-2{
            font-size: 17px; font-family:'Raleway_Bold';
            font-weight: 900;
            /*width: 15vw;*/
            height: 1.6rem;
            margin-top: 25px;
            margin-bottom: 25px;margin-left: 67px;
        }
        .QR{
            width: 100px;
            padding: 0;
            margin-top: -9px;
            margin-left: 40px;
            margin-bottom: 2rem; 
        }

    }

    @media screen and (min-width: 1280px) and (max-width: 1920px){
        .container {
            max-width: 90vw;
            /*text-align-last: center;*/
        }
        .txtGRG{
            padding-left: 13%;
            padding-right: 10%;
            text-align: justify;
        }
        .iconos{
            height:40px; position:absolute; right:1rem; padding:0px; align-content: baseline;
        }
        .font-denuncias{
            font-size: 13px;line-height: 1;
        }
        .circle{
            width:20px; height:20px;margin-left: 18%;
        }
        .titApps{
            font-family:'Raleway_Bold';padding: 0;margin: 0;margin-left: 18%;padding-bottom: 10px;
        }
        .txtApps{
            font-size: 13px;margin-bottom: 20px;text-align: left;margin-left: 18%;;
        }
        .apps{
            padding: 0;    margin: 0;      width: 8vw;            height: 32px;            
        }
        .botones{
            width: 18vw;    margin-left: 35%;    z-index: 10;    position: relative;
        }
        .txtApps2{
            font-size: 10px;    margin-top: 0.5rem;    margin-left: 105px;    width: 160px;
        }
        .appsimg{
            padding: 0;    margin: 0;      width: 13vw;            margin-top: -50px;            margin-left: 90px;
        }
        .txtQR{
            font-size: 15px;    font-family:'Raleway_Bold';        margin-left: 113px;            margin-right: 75px;
        }
        .txtQR-2{
            font-size: 17px; font-family:'Raleway_Bold';
            font-weight: 900;
            /*width: 15vw;*/
            height: 1.6rem;
            margin-top: 25px;
            margin-bottom: 25px;margin-left: 104px;
        }
        .QR{
            width: 120px;
            padding: 0;
            margin-top: -9px;
            margin-left: 40px;
            margin-bottom: 2rem; 
        }

    }

    @media screen and (min-width: 1920px) and (max-width: 2560px){
        .container {
            max-width: 90vw;
            /*text-align-last: center;*/
        }
        .txtGRG{
            padding-left: 12%;
            padding-right: 11%;
            text-align: justify;
        }
        .iconos{
            height:40px; position:absolute; right:1rem; padding:0px; align-content: baseline;
        }
        .font-denuncias{
            font-size: 13px;line-height: 1;
        }
        .circle{
            width:20px; height:20px;margin-left: 25%;
        }
        .titApps{
            font-family:'Raleway_Bold';padding: 0;margin: 0;margin-left: 25%;padding-bottom: 10px;
        }
        .txtApps{
            font-size: 13px;margin-bottom: 20px;text-align: left;margin-left: 25%;
        }
        .botones{
            width: 18vw;    margin-left: 50%;    z-index: 10;    position: relative;
        }
        .apps{
            padding: 0;    margin: 0;      width: 6vw;            height: 40px;            /*margin-left: 106%;*/
        }
        .txtApps2{
            font-size: 10px;            margin-top: 1rem;margin-left: 290px;
        }
        .appsimg{
            padding: 0;    margin: 0;          width: 9vw;            margin-top: -50px;            margin-left: 210px;
        }
        .txtQR{
            font-size: 15px;    font-family:'Raleway_Bold';        margin-left: 25%;           margin-right: 75px;
        }
        .txtQR-2{
            font-size: 17px; font-family:'Raleway_Bold';
            font-weight: 900;
            /*width: 15vw;*/
            height: 1.6rem;
            margin-top: 25px;
            margin-bottom: 25px;margin-left: 49%;
        }
        .QR{
            width: 120px;
            padding: 0;
            margin-top: -9px;
            margin-left: 40px;
            margin-bottom: 2rem; 
        }
    }








        .titulo-denuncias{
            font-size: 25px;
        }
        .titulo-denuncias2{
            font-size: 24px;
        }
        .contenidoApp{
            padding-left: 1.5rem;    padding-right: 0px;
        }


        .btnVideo{
            background-color: dodgerblue;
        }




/*
        .contenidoApp2{
            padding-left: 55px;
        }
        .txtQR{
            width: 25vw; font-size: 15px;
            font-family:'Raleway_Bold';
        }
        .txtQR-2{
            font-size: 17px; font-family:'Raleway_Bold';
            font-weight: 900;
            /*width: 15vw;
            height: 1.6rem;
            margin-top: 25px;
            margin-bottom: 25px;margin-left: 0px;
        }
        .QR{
            width: 90px;
            padding: 0;
            margin-top: -9px;
            margin-left: -100px;
            margin-bottom: 2rem; 
        }*/









    </style>